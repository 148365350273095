import {
  Box,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  FormHelperText,
} from "@material-ui/core";
import { useEffect, useState } from "react";

function createMarkup(markup) {
  return {
    __html: markup,
  };
}

export default function DynamicAnswer({
  text,
  text2 = "",
  custom,
  questionIndex,
  ansindex = "",
  index = 1,
  customName = "",
  formik = {},
  textChange,
}) {
  const relatedIndex = ansindex.replace(/q-3/, "");
  const noInput = "q-5" + relatedIndex + "_1";
  let name = customName || `q-${questionIndex}${relatedIndex}`;
  const [checkName, setCheckName] = useState(name);
  const nameForCustomField = name + "_open";
  const textareaName = `${name}_open`;
  const currentValue = name + "_" + index;

  function isDisabled(val, checkName) {
    if (val === noInput) {
      return formik.values[checkName].some((e) => e !== noInput);
    }
    return formik.values[checkName]?.includes(noInput);
  }

  function onCheckboxChange(e) {
    formik.handleChange(e);
    if (currentValue === noInput) {
      if (!e.target.checked) {
        formik.setFieldValue(textareaName + "_" + index, "");
      }
    } else if (custom && !formik.values?.[name]?.includes(currentValue)) {
      formik.setFieldValue(textareaName + "_" + index, "");
    }
  }

  return (
    <>
      <FormControlLabel
        style={{ marginBottom: "20px", width: "100%" }}
        control={
          <Checkbox
            disabled={isDisabled(currentValue, name)}
            checked={formik.values[name]?.includes(currentValue)}
            value={currentValue}
            inputProps={{
              name,
            }}
            onChange={onCheckboxChange}
            name={name}
          />
        }
        label={
          <>
            <span
              style={{ fontSize: "17px" }}
              dangerouslySetInnerHTML={createMarkup(text)}
            />
            {custom && formik.values?.[name]?.includes(currentValue) && (
              <>
                <span
                  style={{ fontSize: "17px" }}
                  dangerouslySetInnerHTML={createMarkup(text2)}
                />
                <TextareaAutosize
                  defaultValue={formik.values[textareaName + "_" + index]}
                  onChange={textChange}
                  // disabled={!Boolean(answers[nameForCustomField])}
                  name={textareaName + "_" + index}
                  placeholder="..."
                  rowsMin={2}
                  style={{
                    width: "543px",
                    maxWidth: "100%",
                    display: true ? "block" : "none",
                    marginLeft: "0",
                    border:
                      formik.touched[name] &&
                      formik.errors[textareaName + "_" + index]
                        ? "2px solid #f44336"
                        : "",
                  }}
                />
                <FormHelperText error style={{ marginTop: "5px" }}>
                  {formik.touched[textareaName + "_" + index] &&
                    formik.errors[textareaName + "_" + index]}
                </FormHelperText>
              </>
            )}
          </>
        }
      />
    </>
  );
}
