export const mainTable = [
  {
    standalone: true,
    text: "<strong>Wywiad kwestionariuszowy</strong>",
    related: ["q-3_1", "q-3_2", "q-3_3", "q-3_4"],
  },

  { text: "próba IMIENNA, reprezentatywna", nested: true },
  { text: "próba ADRESOWA, reprezentatywna", nested: true },
  { text: "próba CELOWA", nested: true },
  { text: "próba WOLONTARYJNA", nested: true },
  {
    text: "<strong>Zogniskowany wywiad grupowy</strong> (tzw. wywiad fokusowy)",
  },
  {
    text: "<strong>Indywidualny wywiad pogłębiony</strong>",
  },
  {
    text: "<strong>Obserwacja uczestnicząca</strong>",
  },
  {
    standalone: true,
    related: ["q-3_8", "q-3_9"],
    text: "<strong>Eksperyment społeczny</strong> ",
  },
  { text: "w warunkach LABORATORYJNYCH", nested: true },
  { text: "w warunkach TERENOWYCH", nested: true },
  {
    isCustom: true,
    standalone: false,
    text:
      "<strong>Inna metoda</strong> badań ILOŚCIOWYCH wymagająca bezpośredniej interakcji z respondentem (jaka?)",
  },
  {
    isCustom: true,
    text:
      "<strong>Inna metoda</strong> badań JAKOŚCIOWYCH wymagająca bezpośredniej interakcji z respondentem (jaka?)",
  },
];
