import React, { useEffect, Fragment, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { firestore, auth } from "./utils/firebase";
import { Link, navigate } from "@reach/router";
import prevChev from "./images/left-chevron.png";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
const reqText = "Pole wymagane";

const validationSchema = yup.object({
  M1_rokUrodzenia: yup.number("Rok musi być liczbą").required(reqText),
  M2_jobyear: yup
    .number("Rok musi być liczbą")
    .required(reqText)
    .min(1900, "Proszę wpisać prawidłowy rok")
    .max(2021, "Proszę wpisać prawidłowy rok"),
  M3_title: yup.string().required(reqText),
  M4_professional: yup.string().required(reqText),
  M5_zatrudniony: yup.string().required(reqText),
  M6_jednostka: yup.string().required(reqText),
  M7_dyscyplina: yup
    .array()
    .min(1, "Proszę zaznaczyć co najmniej 1 dyscyplinę")
    .max(2, "Proszę zaznaczyć maksymalnie 2 dyscypliny"),
  M7_dyscyplinaInna: yup.string().when("M7_dyscyplina", {
    is: (val) => val.includes("M7_dyscyplina-9"),
    then: yup.string().required("Proszę wpisać dyscyplinę"),
  }),
  M8_stowa: yup
    .array()
    .min(
      1,
      `Proszę wybrać co najmniej jedno stowarzyszenie bądź zaznaczyć "Nie należę do żadnego z powyższych"`
    ),
});

const dataSto = [
  "Polskie Stowarzyszenie Filmu Naukowego",
  "Polskie Towarzystwo Demograficzne",
  "Polskie Towarzystwo Geograficzne",
  "Polskie Towarzystwo Ludoznawcze",
  "Polskie Towarzystwo Nauk Politycznych",
  "Polskie Towarzystwo Pedagogiczne",
  "Polskie Towarzystwo Psychologiczne",
  "Polskie Towarzystwo Religioznawcze",
  "Polskie Towarzystwo Socjologiczne",
  "Towarzystwo Naukowe Organizacji i Kierownictwa",
  "Nie należę do żadnego z powyższych",
];

const dataDysc = [
  {
    name: "",
    text: "geografia społeczno-ekonomiczna i gospodarka przestrzenna",
  },
  { name: "", text: "nauki o komunikacji i mediach" },
  { name: "", text: "nauki o polityce i administracji" },
  { name: "", text: "nauki o zarządzaniu i jakości" },
  { name: "", text: "nauki socjologiczne" },
  { name: "", text: "pedagogika" },
  { name: "", text: "psychologia" },
  { name: "", text: "nauki o kulturze i religii" },
  { name: "", text: "Inne", custom: true },
];

export default function MetricForm() {
  const [wrongPoll, setWrongPoll] = useState(false);

  useEffect(() => {
    const getFromLocal = localStorage.getItem("metric");
    if (getFromLocal) {
      setInitialVals(JSON.parse(getFromLocal));
    }
  }, [null]);
  const [initialVals, setInitialVals] = useState({
    M1_rokUrodzenia: "",
    M2_jobyear: "",
    M3_title: "",
    M4_professional: "",
    M5_zatrudniony: "",
    M6_jednostka: "",
    M7_dyscyplina: [],
    M7_dyscyplinaInna: "",
    M8_stowa: [],
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialVals,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      localStorage.setItem("metric", JSON.stringify(values));
      const ankieta = localStorage.getItem("ankieta");
      const ankietaOK = localStorage.getItem("ankietaOK");
      if (ankietaOK !== "true") {
        setWrongPoll(true);
        return;
      }
      if (ankieta && ankietaOK === "true") {
        firestore
          .collection("Responds")
          .add({
            time: new Date().valueOf(),
            timeStart: +localStorage.getItem("ankietaStart"),
            metric: values,
            form: JSON.parse(ankieta),
          })
          .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
            navigate(`/done`);
          });
      }
    },
  });

  const useStyles = makeStyles((theme) =>
    createStyles({
      wrapper: {
        "& legend": {
          color: "black",
        },
      },
      label: {
        marginRight: "15px",
        color: "black",
        lineHeight: 1.4,
      },
      fieldWrapper: {
        display: "flex",
        marginTop: "55px",
        alignItems: "baseline",
      },
      form: { display: "flex", flexDirection: "column", width: "100%" },
      chev: {
        position: "relative",
        width: "15px",
        top: "-1px",
      },
    })
  );
  const classes = useStyles();
  const noInput = "M8_stowa-11";
  // console.log("formik", formik);
  useEffect(() => {
    const firstErrKey = Object.keys(formik.errors).sort()[0];
    if (firstErrKey) {
      const el = document.querySelector(`[name=${firstErrKey}]`);

      el?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [formik.isSubmitting]);

  function isDisabled(val, checkName) {
    if (val === noInput) {
      return formik.values[checkName].some((e) => e !== noInput);
    }
    return formik.values[checkName]?.includes(noInput);
  }

  function onCheckboxChange(e) {
    formik.handleChange(e);
    if (currentValue === noInput) {
      if (!e.target.checked) {
        formik.setFieldValue(textareaName + "_" + index, "");
      }
    } else if (custom && !formik.values?.[name]?.includes(currentValue)) {
      formik.setFieldValue(textareaName + "_" + index, "");
    }
  }

  return (
    <Box
      mt="60px"
      display="flex"
      flexDirection="column"
      width="100%"
      className={classes.wrapper}
    >
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Box display="flex" alignItems="center">
          <InputLabel className={classes.label}>
            <strong>M1. </strong>Proszę podać rok swojego urodzenia:{" "}
          </InputLabel>
          <TextField
            id="01-M1_rokUrodzenia"
            name="M1_rokUrodzenia"
            type="number"
            inputProps={{
              min: "1900",
            }}
            label=""
            value={formik.values.M1_rokUrodzenia || ""}
            onChange={formik.handleChange}
            error={
              formik.touched.M1_rokUrodzenia &&
              Boolean(formik.errors.M1_rokUrodzenia)
            }
            helperText={
              formik.touched.M1_rokUrodzenia && formik.errors.M1_rokUrodzenia
            }
          />
        </Box>
        <Box
          display="flex"
          alignItems="baseline"
          mt="60px"
          flexDirection="column"
        >
          <InputLabel id="job" className={classes.label}>
            <strong>M2. </strong>Proszę podać rok rozpoczęcia swojej pracy
            naukowej (proszę uwzględnić także okres przygotowywania rozprawy
            doktorskiej)
          </InputLabel>
          <TextField
            id="02-M2_jobyear"
            name="M2_jobyear"
            type="number"
            inputProps={{
              min: "1920",
            }}
            value={formik.values.M2_jobyear}
            onChange={formik.handleChange}
            error={
              formik.touched.M2_jobyear && Boolean(formik.errors.M2_jobyear)
            }
            helperText={formik.touched.M2_jobyear && formik.errors.M2_jobyear}
          />
        </Box>
        <Box className={classes.fieldWrapper}>
          <InputLabel id="title" className={classes.label}>
            <strong>M3. </strong>Proszę wybrać najwyższy posiadany tytuł bądź
            stopień:
          </InputLabel>
          <Select
            labelId="M3_title"
            id="03-title-id"
            width="200px"
            inputProps={{
              name: "M3_title",
              id: "title",
            }}
            value={formik.values.M3_title}
            onChange={formik.handleChange}
            style={{ minWidth: "60px", marginLeft: "10px" }}
          >
            <MenuItem value="mgr">Mgr</MenuItem>
            <MenuItem value="dr">Dr</MenuItem>
            <MenuItem value="drhab">Dr hab.</MenuItem>
            <MenuItem value="profdrhab">Prof. dr hab.</MenuItem>
          </Select>
          <FormHelperText error>
            {formik.touched.M3_title && formik.errors.M3_title}
          </FormHelperText>
        </Box>

        <Box className={classes.fieldWrapper}>
          <FormControl component="fieldset">
            <FormLabel component="legend" id="04-M4_professional">
              <strong>M4. </strong>Posiadam tytuł zawodowy inżyniera
            </FormLabel>
            <RadioGroup
              name="M4_professional"
              value={formik.values.M4_professional}
              style={{ flexDirection: "row" }}
              onChange={formik.handleChange}
            >
              <FormControlLabel value="tak" control={<Radio />} label="Tak" />
              <FormControlLabel value="nie" control={<Radio />} label="Nie" />
            </RadioGroup>
            <FormHelperText error>
              {formik.touched.M4_professional && formik.errors.M4_professional}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box className={classes.fieldWrapper}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <strong>M5. </strong>Jestem zatrudniony/-a na stanowisku profesora
              w macierzystej jednostce naukowej
            </FormLabel>
            <RadioGroup
              name="M5_zatrudniony"
              value={formik.values.M5_zatrudniony}
              style={{ flexDirection: "row" }}
              onChange={formik.handleChange}
            >
              <FormControlLabel value="tak" control={<Radio />} label="Tak" />
              <FormControlLabel value="nie" control={<Radio />} label="Nie" />
            </RadioGroup>
            <FormHelperText error>
              {formik.touched.M5_zatrudniony && formik.errors.M5_zatrudniony}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box
          display="flex"
          alignItems="baseline"
          mt="60px"
          flexDirection="column"
        >
          <InputLabel id="afi" className={classes.label}>
            <strong>M6. </strong>Proszę podać nazwę jednostki naukowej (np.
            nazwę uczelni, nazwę instytutu PAN; zgodnie z Pana/Pani afiliacją),
            w której realizuje Pan/Pani swoje badania:
          </InputLabel>
          <TextField
            id="M6_jednostka"
            name="M6_jednostka"
            fullWidth
            value={formik.values.M6_jednostka}
            onChange={formik.handleChange}
            error={
              formik.touched.M6_jednostka && Boolean(formik.errors.M6_jednostka)
            }
            helperText={
              formik.touched.M6_jednostka && formik.errors.M6_jednostka
            }
          />
        </Box>

        <Box className={classes.fieldWrapper}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" style={{ marginBottom: "20px" }}>
              <strong>M7. </strong>Proszę zaznaczyć maksymalnie dwie dyscypliny,
              w których realizuje Pan/Pani badania:
            </FormLabel>
            {formik.touched["M7_dyscyplina"] &&
              (formik.errors["M7_dyscyplina"] ||
                formik.errors.M7_dyscyplinaInna) && (
                <Alert severity="error" style={{ marginTop: "20px" }}>
                  {formik.touched["M7_dyscyplina"] &&
                    (formik.errors["M7_dyscyplina"] ||
                      formik.errors.M7_dyscyplinaInna)}
                </Alert>
              )}
            <FormGroup>
              {dataDysc.map(({ text, custom }, index) => (
                <Fragment key={"dysc" + index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values["M7_dyscyplina"]?.includes(
                          "M7_dyscyplina-" + (index + 1)
                        )}
                        onChange={formik.handleChange}
                        inputProps={{
                          name: "M7_dyscyplina",
                        }}
                        name="M7_dyscyplina"
                        value={"M7_dyscyplina-" + (index + 1)}
                      />
                    }
                    label={text}
                  />
                  {dataDysc[index].custom &&
                    formik.values["M7_dyscyplina"]?.includes(
                      "M7_dyscyplina-" + (index + 1)
                    ) && (
                      <TextField
                        name="M7_dyscyplinaInna"
                        placeholder="Jakie?"
                        value={formik.values.M7_dyscyplinaInna}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.M7_dyscyplinaInna &&
                          Boolean(formik.errors.M7_dyscyplinaInna)
                        }
                      />
                    )}
                </Fragment>
              ))}
            </FormGroup>
          </FormControl>
        </Box>

        <Box className={classes.fieldWrapper}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel
              component="legend"
              style={{ marginBottom: "20px", lineHeight: 1.35 }}
            >
              <strong>M8. </strong>Spośród poniższych stowarzyszeń naukowych,
              proszę wybrać wszystkie te, do których Pan/Pani należy bądź
              zaznaczyć <em>Nie należę do żadnego z powyższych</em>.
            </FormLabel>
            {!!(formik.touched.M8_stowa && formik.errors.M8_stowa) && (
              <Alert severity="error" style={{ marginTop: "20px" }}>
                {formik.touched.M8_stowa && formik.errors.M8_stowa}
              </Alert>
            )}
            <FormGroup>
              {dataSto.map((text, index) => (
                <Fragment key={"stow" + index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled(
                          "M8_stowa-" + (index + 1),
                          "M8_stowa"
                        )}
                        checked={formik.values["M8_stowa"]?.includes(
                          "M8_stowa-" + (index + 1)
                        )}
                        onChange={formik.handleChange}
                        inputProps={{
                          name: "M8_stowa",
                        }}
                        name="M8_stowa"
                        value={"M8_stowa-" + (index + 1)}
                      />
                    }
                    label={text}
                  />
                </Fragment>
              ))}
            </FormGroup>
          </FormControl>
        </Box>

        {wrongPoll && (
          <Alert severity="warning" style={{ margin: "20px 0" }}>
            Proszę prawidłowo wypełnić <Link to="/form">ankietę</Link>
          </Alert>
        )}

        <Box mt="60px" display="flex">
          <Button
            color="secondary"
            style={{ marginRight: "60px" }}
            variant="contained"
            onClick={() => {
              localStorage.setItem("metric", JSON.stringify(formik.values));
              navigate("/form", { state: { step: 9 } });
            }}
          >
            <img
              src={prevChev}
              className={classes.chev}
              style={{ marginRight: "10px" }}
              alt=""
            />{" "}
            Wróć do ankiety
          </Button>
          <Button
            style={{ marginRight: "10px" }}
            color="primary"
            variant="contained"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Wyślij ankietę
          </Button>
          {formik.isSubmitting && <CircularProgress />}
        </Box>
      </form>
    </Box>
  );
}
