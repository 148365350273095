import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ThemeProvider } from '@material-ui/styles'
import { Theme } from './utils/theme'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Router, RouteComponentProps, Link } from '@reach/router'
import Home from './Home'
import Poll from './Poll'
import Box from '@material-ui/core/Box/Box'
import pattern from './images/bg-pattern.png'
import Footer from './Footer'
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline'
import Metric from './Metric'
import Done from './Done'
import { ScrollToTop } from './ScrollTop'
import Dash from './Dash'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            // background: theme.palette.primary.contrastText,
        },
        header: {
            background: `url(${pattern}), linear-gradient(to left,#005CA7,#0094CE)`,
            borderBottom: `3px solid #f8c53a`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '20px 0',
        },
        wrapper: {
            maxWidth: '1000px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
        },
        paper: {
            padding: '20px',
            borderRadius: '10px',
        },
        mainTitle: {
            padding: '20px',
            color: 'white',
            fontWeight: 100,
        },
        footer: {
            background: '#f7f7f7',
            height: '100px',
            display: 'block',
            width: '100%',
            borderTop: `3px solid #f8c53a`,
        },
    })
)

function App() {
    const classes = useStyles()
    return (
        <ThemeProvider theme={Theme}>
            <CssBaseline />
            <div className={classes.root}>
                <header className="App-header">
                    <Box className={classes.header}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            <Typography
                                variant="h4"
                                component="h1"
                                align="center"
                                className={classes.mainTitle}
                            >
                                Pandemia COVID-19 a metodyka <br /> badań
                                społecznych
                            </Typography>
                        </Box>
                    </Box>
                </header>

                <Router primary={false}>
                    {/* @ts-ignore */}
                    <ScrollToTop path="/">
                        <Home path="/" />

                        <Poll path="form" />
                        <Metric path="personal" />
                        <Done path="done" />
                        <Dash path="dashboard" />
                    </ScrollToTop>
                </Router>
                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default App
