import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button/Button";
import Link from "@material-ui/core/Link/Link";
import { RouteComponentProps, Link as RouterLink } from "@reach/router";
import React from "react";
import { ReactComponent as VirusSVG } from "./images/virus-outline.svg";

export default function Home({}: RouteComponentProps) {
  const wrapper: any = {
    maxWidth: "1000px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
  };
  return (
    <Box style={wrapper}>
      <Box
        style={{
          columnCount: 1,
          marginTop: "20px",
          color: "#4e5154",
          lineHeight: 1.4,
          fontSize: "18px",
          textAlign: "justify",
          padding: "20px",
        }}
      >
        <VirusSVG
          style={{
            opacity:  0.032,
            position: "absolute",
            width: "800px",
            height: "800px",
            left: "50%",
            transform: " translateX(-50%)",
            maxWidth: "100%",
            pointerEvents: "none",
          }}
        />
        <p>Szanowni Państwo,</p>
        <p style={{ fontWeight: 500 }}>
          zwracamy się do Państwa – ekspertów z dziedziny nauk społecznych – z
          prośbą o udział w ogólnopolskim badaniu dotyczącym metodyki badań w
          dobie pandemii COVID-19.
        </p>
        <p>
          Przedłużający się stan niepewności pandemicznej dotyka m.in. dziedzinę
          nauk społecznych. Pod dużym znakiem zapytania stanęła możliwość
          rzetelnej realizacji badań przy użyciu metod zakładających
          bezpośrednią interakcję badacza/ankietera z respondentem.
          Jednocześnie, od roku pracujemy w warunkach{" "}
          <span style={{ textDecoration: "underline" }}>
            braku jednoznacznych, ogólnokrajowych wytycznych
          </span>{" "}
          ze strony szeroko rozumianego otoczenia instytucjonalnego dotyczących
          realizacji takich prac. Obawiamy się, że utrzymywanie się takiego
          stanu może wpłynąć na spadek jakości badań społecznych, choćby w
          wymiarze ich porównywalności czy replikacyjności. W związku z
          powyższym, jako badacze sami wykorzystujący metodykę nauk społecznych,
          widzimy konieczność wyznaczenia nowych standardów naszych badań na
          czas pandemii COVID-19 i podobnych kryzysów epidemicznych.
        </p>{" "}
        <p style={{ fontWeight: 500 }}>
          Głównym celem naszego projektu jest określenie skali problemu, a także
          zebranie ogólnopolskich doświadczeń w zakresie realizowania badań
          społecznych w dobie pandemii. Praktycznym efektem naszych prac będzie,
          wypracowana dzięki Państwu, lista dobrych praktyk, które zagwarantują
          możliwość realizacji badań społecznych bez szkody dla rzetelności
          wyników uzyskiwanych przez badaczy posiłkujących się metodyką nauk
          społecznych.
        </p>
        <p>
          <u>
            Mając na uwadze wagę problemu, liczymy na Państwa pomoc polegającą
            na wypełnieniu niniejszej ankiety
          </u>
          . Czas jej wypełnienia będzie różnił się, w zależności od udzielanych
          przez Państwa odpowiedzi, ale <u>nie powinien przekroczyć 15 min</u>.
          Państwa opinie zachowają w pełni poufny charakter i będą użyte
          wyłącznie do zaprezentowania szerokiego obrazu zjawiska, zaś
          pojedyncza ankieta nie będzie analizowana ani prezentowana w
          jakiejkolwiek formie.
        </p>
        <p>
          Jednocześnie, serdecznie{" "}
          <span style={{ textDecoration: "underline" }}>
            prosimy o rozpowszechnienie tej ankiety wśród możliwie dużej grupy
            badaczy{" "}
          </span>
          realizujących swoje prace przy użyciu metodyki nauk <u>społecznych</u>
          . Każdego/-ą z Państwa prosimy o{" "}
          <span style={{ textDecoration: "underline" }}>
            jednokrotne wypełnienie ankiety
          </span>
          , nawet jeśli trafiła ona do Państwa z różnych źródeł oraz w różnym
          czasie. Wszelkie pytania dotyczące naszych badań proszę kierować na
          adres e-mail:
        </p>
        <ul>
          <li>
            kierownika projektu - mgr Marcina Rechcińskiego (
            <Link href="mailto:marcin.rechcinski@uj.edu.pl">
              marcin.rechcinski@uj.edu.pl
            </Link>
            )
          </li>
          <li>
            opiekuna naukowego projektu - prof. dr hab. Małgorzaty
            Grodzińskiej-Jurczak (
            <Link href="mailto:m.grodzinska-jurczak@uj.edu.pl">
              m.grodzinska-jurczak@uj.edu.pl
            </Link>
            )
          </li>
        </ul>
        <p style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="form"
          >
            Wypełnij ankietę
          </Button>
        </p>
      </Box>
    </Box>
  );
}
