import { createMuiTheme } from "@material-ui/core/styles";
// import "@fontsource/roboto/latin-ext.css"; // All weights with normal style included.

// const fontMedium = {
//   fontFamily: "DinProMedium",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `
//             local('DinProMedium'),
//             url(${DinProMedium}) format('woff')
//           `,
// };

// const fontLight = {
//   fontFamily: "DinProLight",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: "normal",
//   src: `local('DinProLight'),
//         url(${DinProLight}) format('woff2')
//         `,
// };

// #005ca7

const customPalette = {
  text: {
    primary: "#005ca7",
  },
  primary: {
    main: "#0278C9",
  },
  secondary: {
    main: "#f8c53a",
  },
  error: {
    main: "#DE5050",
  },
  background: {
    default: "red",
  },
};

// export const Theme = createMuiTheme({
//   palette: {
//     ...customPalette,
//   },
//   typography: {
//     fontSize: 15,
//     htmlFontSize: 15,
//     fontFamily: "Roboto",
//     h1: {
//       fontFamily: "Roboto",
//       color: "white",
//       fontSize: "1.71rem",
//     },
//     h5: {
//       fontFamily: "Roboto",
//     },
//   },
// });

export const Theme = createMuiTheme({
  typography: {
    fontSize: 15,
    htmlFontSize: 15,
    fontFamily: "Roboto",
    fontWeightBold: 500,
  },
  palette: {
    primary: {
      main: "#005ca7",
    },
    secondary: {
      main: "#f8c53a",
      // dark: will be calculated from palette.secondary.main,
    },
    text: {
      secondary: "#6c757d",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    // background: {
    //   // default: "#F1F1E6",
    // },
  },
});
