import { Box, TextareaAutosize } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useState } from "react";
function regFirstWords(s, n) {
  // ?: non-capturing subsequent sp+word.Change {} if you want to require n instead of allowing fewer
  var a = s.match(
    new RegExp("[\\w\\.]+" + "(?:[\\s-]*[\\w\\.]+){0," + (n - 1) + "}")
  );
  return a === undefined || a === null ? "" : a[0];
}

export default function ProsCons({
  error = false,
  formik,
  recs,
  debouncedHandler,
  questionIndex,
  anyRecs,
}) {
  const [expanded, setExpand] = useState([]);

  function toggleExpand(index) {
    const foundIndex = expanded.findIndex((i) => i === index);
    if (foundIndex === -1) {
      setExpand([...expanded, index]);
    } else {
      setExpand([
        ...expanded.slice(0, foundIndex),
        ...expanded.slice(foundIndex + 1),
      ]);
    }
  }

  return !anyRecs ? (
    <Box textAlign="center">
      Proszę wypełnić co najmniej jedną rekomendację w poprzednim pytaniu.
    </Box>
  ) : (
    recs.map((rec, RecIndex) => {
      const recText = formik.values[`q-8-rek-${RecIndex + 1}`];
      if (recText) {
        return (
          <Box key={RecIndex}>
            <p style={{ fontStyle: "italic" }}>
              Rekomendacja #{RecIndex + 1} -{" "}
              {expanded.includes(RecIndex)
                ? recText
                : regFirstWords(recText, 4) + "..."}
              <Box
                style={{ cursor: "pointer", fontSize: "15px" }}
                component="span"
                color="#004074"
                onClick={() => toggleExpand(RecIndex)}
              >
                {" "}
                {regFirstWords(recText, 4) === regFirstWords(recText, 5)
                  ? ""
                  : expanded.includes(RecIndex)
                  ? "(zwiń)"
                  : "(rozwiń)"}
              </Box>
            </p>
            <FormHelperText error style={{ marginTop: "5px" }}>
              {RecIndex < 2 &&
                formik.touched[`q-9-mocne_${RecIndex + 1}`] &&
                formik.errors[`q-9-mocne_${RecIndex + 1}`]}
            </FormHelperText>{" "}
            <TextareaAutosize
              onChange={debouncedHandler}
              placeholder="Mocne strony"
              defaultValue={formik.values[`q-9-mocne_${RecIndex + 1}`]}
              name={`q-9-mocne_${RecIndex + 1}`}
              rowsMin={3}
              style={{ width: "100%" }}
            />
            <br />
            <br />
            <FormHelperText error style={{ marginTop: "5px" }}>
              {RecIndex < 2 &&
                formik.touched[`q-9-slabe_${RecIndex + 1}`] &&
                formik.errors[`q-9-slabe_${RecIndex + 1}`]}
            </FormHelperText>{" "}
            <TextareaAutosize
              onChange={debouncedHandler}
              placeholder="Słabe strony"
              defaultValue={formik.values[`q-9-slabe_${RecIndex + 1}`]}
              name={`q-9-slabe_${RecIndex + 1}`}
              rowsMin={3}
              style={{ width: "100%" }}
            />
          </Box>
        );
      }
    })
  );
}
