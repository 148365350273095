import { RouteComponentProps } from '@reach/router'
import { divide } from 'lodash'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { firestore } from './utils/firebase'

export default function Dash({}: RouteComponentProps) {
    const [count, setCount] = useState<string>('-')
    const [lastWhen, setLastWhen] = useState<string>('-')
    const [dys, setDys] = useState({})
    useEffect(() => {
        const p = {}
        const q1 = firestore
            .collection('Responds')
            .get()
            .then((querySnap) => {
                querySnap.docs.forEach((e) => {
                    const checked = e.get('metric')['M7_dyscyplina']
                    if (checked) {
                        checked.forEach((res) => {
                            if (p[res] !== undefined) {
                                p[res] += 1
                            } else {
                                p[res] = 1
                            }
                        })
                    }
                })
                setDys(p)
            })
        const unsubscribeFromFirestore = firestore
            .collection('Responds')
            .where('time', '>=', 1618923757381)
            .get()
            .then((querySnapshot) => {
                setCount(querySnapshot.size.toString())
                const len = querySnapshot.docs.length
                // querySnapshot.forEach((doc) => {
                //     // doc.data() is never undefined for query doc snapshots
                //     console.log(doc.id, " => ", doc.data());
                // });
            })

        return () => {
            //   unsubscribeFromFirestore();
        }
    }, [])
    return (
        <div>
            <div style={{ marginBottom: '10px' }}>
                Liczba wypełnionych ankiet: {count}
            </div>
            {Object.keys(dys)
                .sort()
                .map((key) => (
                    <div key={key}>
                        {key} - {dys[key]}
                    </div>
                ))}
        </div>
    )
}
