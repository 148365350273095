import logoUj from "./images/logo-uj-min.png";
import logoIni from "./images/logo-ini3.png";
import pattern from "./images/bg-pattern.png";
import React from "react";
import Box from "@material-ui/core/Box/Box";

export default function Footer() {
  return (
    <footer>
      <Box
        style={{
          marginTop: "60px",
          paddingTop: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTop: "2px solid #f8c53a",
          backgroundImage: `url(${pattern})`,
        }}
      >
        <img src={logoUj} alt="logoUJ" />
        <img
          src={logoIni}
          alt=""
          style={{ width: "150px", marginLeft: "80px" }}
        />
      </Box>
      <Box
        textAlign="center"
        style={{
          textAlign: "center",
          marginTop: "30px",
          /* margin-bottom: 30px, */
          padding: "20px 0",
          background: "#f7c500",
        }}
      >
        Badania realizowane są przy wsparciu finansowym Priorytetowego Obszaru
        Badawczego FutureSoc w ramach Programu Strategicznego Inicjatywa
        Doskonałości w Uniwersytecie Jagiellońskim.
      </Box>
    </footer>
  );
}
