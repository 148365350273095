import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    TextareaAutosize,
    TextField,
} from '@material-ui/core'
import { useFormik } from 'formik'
import Alert from '@material-ui/lab/Alert'
import * as yup from 'yup'
import {
    RouteComponentProps,
    Link as RouterLink,
    navigate,
} from '@reach/router'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Question from './Question'

import { firestore, auth } from './utils/firebase'
import React from 'react'
import debounce from 'lodash/debounce'
import remove from 'lodash/remove'
import DynamicAnswer from './DynamicAnswer'
import { dynamiAnswers, q6Data } from './data'
import ProsCons from './ProsCons'
import RadioTable from './RadioTable'
import { mainTable } from './mainTable'
import CheckTable from './CheckTable'
import { useDebouncedCallback } from 'use-debounce'

import nextChev from './images/right-chevron.png'
import prevChev from './images/left-chevron.png'
import './custom.css'
// import { Formik, Field, Form } from 'formik'

interface QuestionInterface {
    type: string
    question: string
    options?: string[]
    rangeMax?: number
    relatedAnswerName?: string
    relatedMap?: Record<string, string>
    relayOn?: boolean
    altindex?: number
    altQuestion?: string
    qNumber?: number
}

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            // minWidth: 650,
        },
        label: {
            margin: 0,
        },
        chev: {
            position: 'relative',
            width: '15px',
            top: '-1px',
        },
        head: {
            background: '#f1f1f1',
            color: 'white',
            textAlign: 'center',
            '&>tr>th': {
                borderBottom: 0,
            },
        },
    })
)

const relatedData = {
    'q-3_1': 'Wywiad kwestionariuszowy – (próba imienna, reprezentatywna)',
    'q-3_2': 'Wywiad kwestionariuszowy – (próba adresowa, reprezentatywna) ',
    'q-3_3': 'Wywiad kwestionariuszowy – (próba celowa)',
    'q-3_4': 'Wywiad kwestionariuszowy – (próba wolontaryjna)',
    'q-3_5': 'Zogniskowany wywiad grupowy (tzw. wywiad fokusowy)',
    'q-3_6': 'Indywidualny wywiad pogłębiony',
    'q-3_7': 'Obserwacja uczestnicząca',
    'q-3_8': 'Eksperyment społeczny (w warunkach LABORATORYJNYCH)',
    'q-3_9': 'Eksperyment społeczny (w warunkach TERENOWYCH)',
    'q-3_10': 'Inna metoda badań ILOŚCIOWYCH',
    'q-3_11': 'Inna metoda badań JAKOŚCIOWYCH',
}

export default function Poll({ location }: RouteComponentProps) {
    const requestSimpleArea = debounce(({ target }) => {
        setAnswers({ ...answers, [target.name]: target.value })
    }, 1000)

    const regularQArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const shortQArr = [0, 1, 2, 7, 8, 9]

    const wyzwaniaInput = useRef(null)
    // @ts-ignore

    const [area, setArea] = useState({})
    const [pollTable, setPollTable] = useState<any>([])
    const [questions, setQuestions] = useState<QuestionInterface[]>([])
    const [answers, setAnswers] = useState<
        Record<string, string | number> | {}
    >({
        // "q-6-not-coped": "",
        // "q-6-textarea-metoda": "",
        'q-1-radio-plan': '',
    })
    const [showAlert, setShowAlert] = useState('')
    const [errors, setErrors] = useState([])
    const [relMap, setRelMap] = useState({})
    const [touched, setTouched] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        localStorage.setItem('ankietaOK', 'false')
        if (!localStorage.getItem('ankietaStart')) {
            localStorage.setItem('ankietaStart', Date.now().toString())
        }
        const unsubscribeFromFirestore = firestore
            .collection('Form')
            .onSnapshot((snapshot) => {
                const quest: any = snapshot.docs[0].data().questions
                const fields: any = snapshot.docs[1].data().fields

                setPollTable(fields)
                setQuestions(quest)
            })

        return () => {
            unsubscribeFromFirestore()
        }
    }, [])

    function getRelated(checkedArr): any {
        const arr: string[] | [] = []
        const ob = {}
        checkedArr.forEach((name) => {
            // @ts-ignore
            if (relatedData[name]) {
                ob[name] = { text: relatedData[name], related: name }
            }
            // relatedData[name] && arr.push(relatedData[name]);
        })
        return ob
    }

    function createMarkup(markup) {
        return {
            __html: markup,
        }
    }

    // function checkIfAnyRec() {
    //   return recs.some(({ text }) => Boolean(text));
    // }

    // const showAll =

    ////////// -NOOOOOWE

    function createRadioInitial(count = 5) {
        const initials = {}
        let elNumber = 1

        Object.keys(mainTable).forEach((key, index) => {
            const baseName = `q-1_${elNumber}`
            if (!mainTable[key].standalone) {
                initials[baseName] = ''
                if (mainTable[key].isCustom) {
                    initials[baseName + '_open'] = ''
                }
                elNumber++
            }
        })
        return { ...initials }
    }

    const radioIinitials = createRadioInitial()
    const noCustomKeys = Object.keys(radioIinitials).filter(
        (key) => !key.endsWith('open') && key !== 'q-1_11' && key !== 'q-1_10'
    )

    function makeSchemaForRadios() {
        const obj = {}
        noCustomKeys.forEach((key) => {
            obj[key] = yup
                .string()
                .required('Proszę udzielić odpowiedzi w każdym wierszu')
        })
        return obj
    }

    const reqText = 'Pole wymagane'
    const schemaObFor4 = {}
    const initialQ4Ob = {}
    const initialQ5Ob = {}
    const schemaQ5Ob = {}
    // const initialQ5ObOpen = {};
    const q5Openarr = [1, 2, 3, 4, 7]

    function fillQrInitial() {
        for (let i = 1; i <= 11; i++) {
            initialQ4Ob['q-4_' + i + '_pop'] = ''
            initialQ4Ob['q-4_' + i + '_proba'] = ''
            initialQ5Ob['q-5_' + i] = []
            q5Openarr.forEach((e) => {
                initialQ5Ob['q-5_' + i + '_open_' + e] = ''
            })
        }
    }
    fillQrInitial()
    Object.keys(initialQ4Ob).forEach((key) => {
        schemaObFor4[key] = yup.string().when('q-3', {
            is: (val) => {
                const related = key
                    .replace(/q-\d+/, 'q-3')
                    .replace(/_pop|_proba/, '')
                return val.includes(related)
            },
            then: yup.string().required('Proszę wypełnić wszystkie pola'),
        })
    })

    Object.keys(initialQ5Ob).forEach((key) => {
        if (key.includes('open')) {
            const related = key.replace(/_open_\d+/, '')
            schemaQ5Ob[key] = yup.string().when(related, {
                is: (val) => {
                    return val.includes(key.replace('_open', ''))
                },
                then: yup.string().required('Proszę wypełnić pole tekstowe'),
            })
        } else {
            schemaQ5Ob[key] = yup.array().when('q-3', {
                is: (val) => {
                    const related = key.replace(/q-\d+/, 'q-3')
                    return val.includes(related)
                },
                then: yup
                    .array()
                    .min(1, 'Proszę zaznaczyć co najmniej jedną odpowiedź'),
            })
        }
    })
    const altValidationSchema = yup.object({
        ...makeSchemaForRadios(),
        'q-1_10': yup.string().when('q-1_10_open', {
            is: (val) => Boolean(val),
            then: yup.string().required('Proszę zaznaczyć ocenę przy metodzie'),
        }),
        'q-1_11': yup.string().when('q-1_11_open', {
            is: (val) => Boolean(val),
            then: yup.string().required('Proszę zaznaczyć ocenę przy metodzie'),
        }),
        'q-2': yup.string().required('Proszę wybrać jedną z odpowiedzi'),
    })

    const validationSchema = yup.object({
        ...makeSchemaForRadios(),

        'q-1_10': yup.string().when('q-1_10_open', {
            is: (val) => Boolean(val),
            then: yup.string().required('Proszę zaznaczyć ocenę przy metodzie'),
        }),
        'q-1_11': yup.string().when('q-1_11_open', {
            is: (val) => Boolean(val),
            then: yup.string().required('Proszę zaznaczyć ocenę przy metodzie'),
        }),
        'q-2': yup.string().required('Proszę wybrać jedną z odpowiedzi'),
        'q-3': yup.array().when('q-2', {
            is: (val) => val === 'Tak',
            then: yup.array().min(1, 'Proszę wybrać jedną z odpowiedzi'),
        }),
        'q-6': yup.array().when('q-2', {
            is: (val) => val === 'Tak',
            then: yup.array().min(1, 'Proszę wybrać jedną z odpowiedzi'),
        }),
        'q-6_open_7': yup.string().when('q-6', {
            is: (val) => val.includes('7'),
            then: yup.string().required('Proszę wypełnić pole tekstowe'),
        }),
        // "q-7-wyzwania": yup.string().when("q-7-nies", {
        //   is: (val) => !val.includes("On"),
        //   then: yup.string().required("Proszę opisać wyzwania lub zaznaczyć pole"),
        // }),
        'q-8-rek-2': yup.string(),
        'q-7-nie': yup.array().when('q-7-wyzwania', {
            is: (val) => !Boolean(val),
            then: yup
                .array()
                .min(1, 'Proszę opisać wyzwania lub zaznaczyć pole'),
        }),
        'q-8-rek-1': yup.string().required('Proszę wypełnić rekomendację'),
        ...schemaObFor4,
        ...schemaQ5Ob,
        'q-9-slabe_1': yup.string().required('Pole wymagane'),
        'q-9-mocne_1': yup.string().required('Pole wymagane'),
        'q-9-mocne_2': yup.string().when('q-8-rek-2', {
            is: (val) => Boolean(val),
            then: yup.string().required('Pole wymagane'),
        }),
        'q-9-slabe_2': yup.string().when('q-8-rek-2', {
            is: (val) => Boolean(val),
            then: yup.string().required('Pole wymagane'),
        }),
        // "q-9-mocne_3": yup.string().required("Pole wymagane"),
    })

    const [initialVals, setInitialVals] = useState({
        ...createRadioInitial(),
        'q-2': '',
        'q-3': [],
        'q-5': [],
        'q-3_open': '',
        'q-7_open': '',
        ...initialQ4Ob,
        ...initialQ5Ob,
        'q-6': [],
        'q-6_open_7': '',
        'q-7-wyzwania': '',
        'q-7-nie': [],
        'q-8-rek-1': '',
        'q-9-slabe_1': '',
        'q-9-mocne_1': '',
        'q-8-rek-2': '',
        'q-9-mocne_2': '',
        'q-9-slabe_2': '',
    })

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: initialVals,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const str = JSON.stringify(values)
            localStorage.setItem('ankieta', str)
            localStorage.setItem('ankietaOK', String(formik.isValid))
            navigate('/personal')
        },
    })
    const [currentQuestion, setCurrentQuestion] = useState<number>(() => {
        const inits = localStorage.getItem('ankieta')
        if (inits) {
            const vals = JSON.parse(inits)
            // @ts-ignore
            return vals?.['q-2'] ? location?.state?.step || 1 : 1
        }

        return 1
    })
    const realQNumber =
        formik.values['q-2'] === 'Tak'
            ? regularQArr[currentQuestion]
            : shortQArr[currentQuestion]

    const debouncedChange = useDebouncedCallback((value) => {
        // event.persist();
        onRefresh(value)
        // formik.handleChange(event);
    }, 1000)

    function onRefresh(formikNow) {
        localStorage.setItem('ankieta', JSON.stringify(formik.values))
    }

    useEffect(() => {
        const getFromLocal = localStorage.getItem('ankieta')
        if (getFromLocal) {
            setInitialVals(JSON.parse(getFromLocal))
        }
    }, [null])

    useEffect(() => {
        debouncedChange(formik)
    }, [formik.isValidating])

    useEffect(() => {
        const firstErrKey = Object.keys(formik.errors).sort()[0]
        const reg = /q-\d+/
        const match = firstErrKey?.match(reg)
        if (match && match.length && match[0]) {
            document
                .getElementById(match[0])
                ?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [formik.isSubmitting])

    function radioError() {
        const error = Object.keys(formik.errors).find((key) => {
            return (
                Object.keys(radioIinitials).includes(key) && formik.touched[key]
            )
        })

        return error ? formik.errors[error] : ''
    }
    const relatedObject = getRelated(formik.values['q-3'])

    function showQ5Error(ind) {
        const er = Object.keys(formik.errors).find((err) =>
            err.startsWith(ind.replace(/q-3/, 'q-5'))
        )
        return er && formik.touched[er] && !er.includes('open')
            ? formik.errors[er]
            : ''
    }

    const notEmpty2 = formik.values['q-2'] !== ''

    const debouncedTextChange = useDebouncedCallback((event) => {
        // event.persist();
        formik.handleChange(event)
    }, 200)
    const handleOnChange = useCallback((event) => {
        event.persist()

        // const newValue = event.currentTarget.value;
        // setInnerValue(newValue);
        debouncedTextChange(event)
    }, [])

    function prevQuestion() {
        if (currentQuestion === 1) {
            return
        }
        setCurrentQuestion((prev) => prev - 1)
        window.scrollTo(0, 0)
    }

    async function nextQuestion() {
        const currentErrors = await formik.validateForm()
        // console.log("currentErrors", currentErrors);

        const relevantError = Object.keys(currentErrors).find((key) =>
            key.startsWith('q-' + realQNumber)
        )

        const allToTouch = {}

        const allTouched = Object.keys(currentErrors)
            .filter((key) => key.startsWith('q-' + realQNumber))
            .forEach((key) => {
                allToTouch[key] = true
            })

        if (relevantError) {
            setShowAlert(currentErrors[relevantError])
            await formik.setTouched(allToTouch, false)
            // @ts-ignore
            document.querySelector(`[name=${relevantError}]`).focus()
        } else {
            setShowAlert('')
            await formik.setTouched({}, false)
            setCurrentQuestion((prev) => prev + 1)
            window.scrollTo(0, 0)
        }

        // formik.setTouched({}, false)
    }

    const filledRecs = (vals) =>
        Object.keys(vals).filter(
            (key) =>
                key === 'q-8-rek-1' ||
                (key.startsWith('q-8-rek-') && vals[key].trim() !== '')
        )
    // console.log("filledRecs", filledRecs);
    const [recs, setRecs] = useState([])
    useEffect(() => {
        const filled = filledRecs(initialVals)
        setRecs([...filled])
    }, [initialVals])
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {questions.map(
                    (
                        { question, type, rangeMax, options, qNumber, ...rest },
                        questionIndex
                    ) => {
                        let compareTo = qNumber
                        if (formik.values['q-2'] === 'Nie') {
                            if (rest.relayOn) {
                                return null
                            }
                            compareTo = rest.altindex || qNumber
                        }

                        return compareTo !== currentQuestion ? null : (
                            <Box key={'Box' + questionIndex}>
                                {formik.values['q-2'] === 'Tak' ? (
                                    <Question
                                        sticky={questionIndex === 4}
                                        number={
                                            formik.values['q-2'] !== 'Tak' &&
                                            rest.altindex
                                                ? rest.altindex
                                                : qNumber
                                        }
                                        key={'question' + questionIndex}
                                    >
                                        {question}
                                    </Question>
                                ) : formik.values['q-2'] === '' &&
                                  questionIndex > 1 ? null : (
                                    <Question
                                        sticky={questionIndex === 4}
                                        forceId={qNumber}
                                        number={
                                            formik.values['q-2'] !== 'Tak' &&
                                            rest.altindex
                                                ? rest.altindex
                                                : qNumber
                                        }
                                        key={'question' + questionIndex}
                                    >
                                        {question}
                                    </Question>
                                )}
                                <Box
                                    maxWidth={1100}
                                    mx="auto"
                                    key={'type' + questionIndex}
                                >
                                    {type === 'scale' && (
                                        <>
                                            {!!radioError() && (
                                                <Alert
                                                    severity="error"
                                                    style={{
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    {radioError()}
                                                </Alert>
                                            )}
                                            <RadioTable formik={formik} />
                                        </>
                                    )}
                                    {type === 'radio' && ( // q-2
                                        <FormControl
                                            component="fieldset"
                                            key={'Box' + questionIndex}
                                            // @ts-ignore
                                            error={
                                                formik.touched['q-2'] &&
                                                Boolean(formik.errors['q-2'])
                                            }
                                        >
                                            <RadioGroup
                                                name={`q-2`}
                                                // @ts-ignore
                                                value={formik.values['q-2']}
                                                onChange={formik.handleChange}
                                            >
                                                {options?.map(
                                                    (opt, optIndex) => (
                                                        <FormControlLabel
                                                            key={
                                                                questionIndex +
                                                                'radio' +
                                                                optIndex
                                                            }
                                                            value={opt.toString()}
                                                            control={<Radio />}
                                                            label={opt}
                                                            className={
                                                                classes.label
                                                            }
                                                        />
                                                    )
                                                )}
                                            </RadioGroup>

                                            {formik.touched['q-2'] &&
                                                formik.errors['q-2'] && (
                                                    <Alert severity="error">
                                                        {formik.touched[
                                                            'q-2'
                                                        ] &&
                                                            formik.errors[
                                                                'q-2'
                                                            ]}
                                                    </Alert>
                                                )}
                                        </FormControl>
                                    )}
                                    {notEmpty2 &&
                                        type === 'checkbox' && ( //q-3
                                            <CheckTable
                                                formik={formik}
                                                handleChange={handleOnChange}
                                            />
                                        )}
                                    {notEmpty2 && //q-4
                                        type === 'relatedTable' &&
                                        (formik.values['q-3'].length ? (
                                            <CheckTable
                                                formik={formik}
                                                type="related"
                                                name="q-4"
                                                handleChange={handleOnChange}
                                            />
                                        ) : (
                                            <Box textAlign="center">
                                                Nie zaznaczono żadnej metody w
                                                pytaniu 3.
                                            </Box>
                                        ))}
                                    {notEmpty2 && //q-5
                                        type === 'dynamic' &&
                                        (!formik.values['q-3'].length ? (
                                            <Box textAlign="center">
                                                Nie zaznaczono żadnej metody w
                                                pytaniu 3.
                                            </Box>
                                        ) : (
                                            Object.keys(relatedObject).map(
                                                // @ts-ignore
                                                (item, index) => (
                                                    <Box
                                                        key={
                                                            index +
                                                            'dynamic' +
                                                            questionIndex
                                                        }
                                                    >
                                                        {/* {index !== 0 && (
                              <Alert
                                severity="warning"
                                icon={false}
                                variant="outlined"
                              >
                                <Box
                                  dangerouslySetInnerHTML={createMarkup(
                                    rest.altQuestion
                                  )}
                                />
                              </Alert>
                            )} */}
                                                        <Box
                                                            mb={1.3}
                                                            style={{
                                                                fontSize:
                                                                    '17px',
                                                                fontWeight: 500,
                                                                fontStyle:
                                                                    'italic',
                                                            }}
                                                        >
                                                            {
                                                                relatedObject[
                                                                    item
                                                                ].text
                                                            }
                                                        </Box>
                                                        <Box>
                                                            {!!showQ5Error(
                                                                relatedObject[
                                                                    item
                                                                ].related
                                                            ) && (
                                                                <Alert
                                                                    severity="error"
                                                                    style={{
                                                                        marginTop:
                                                                            '5px',
                                                                    }}
                                                                >
                                                                    {showQ5Error(
                                                                        relatedObject[
                                                                            item
                                                                        ]
                                                                            .related
                                                                    )}
                                                                </Alert>
                                                            )}{' '}
                                                            {dynamiAnswers.map(
                                                                (
                                                                    {
                                                                        text,
                                                                        custom,
                                                                        text2,
                                                                    },
                                                                    ansindex
                                                                ) => {
                                                                    const props =
                                                                        {
                                                                            text,
                                                                            text2,
                                                                            custom,
                                                                            questionIndex: 5,
                                                                            area,
                                                                            errors,
                                                                            ansindex:
                                                                                relatedObject[
                                                                                    item
                                                                                ]
                                                                                    .related,
                                                                            index:
                                                                                ansindex +
                                                                                1,
                                                                            formik,
                                                                        }
                                                                    // @ts-ignore
                                                                    return (
                                                                        <DynamicAnswer
                                                                            key={`dynamic-q-${questionIndex}-${ansindex}-${relatedObject[item].related}`}
                                                                            {...props}
                                                                            textChange={
                                                                                handleOnChange
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            )}
                                                        </Box>
                                                    </Box>
                                                )
                                            )
                                        ))}
                                    {notEmpty2 && type === 'textarea' && (
                                        <>
                                            {formik.touched['q-7-wyzwania'] ||
                                                (formik.touched['q-7-nie'] &&
                                                    (formik.errors[
                                                        'q-7-wyzwania'
                                                    ] ||
                                                        formik.errors[
                                                            'q-7-nie'
                                                        ]) && (
                                                        <Alert
                                                            severity="error"
                                                            style={{
                                                                marginBottom:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Proszę uzupełnić
                                                            jedno z pól.
                                                        </Alert>
                                                    ))}
                                            <TextareaAutosize
                                                disabled={
                                                    !!formik.values['q-7-nie']
                                                        .length
                                                }
                                                ref={wyzwaniaInput}
                                                onChange={handleOnChange}
                                                defaultValue={
                                                    formik.values[
                                                        'q-7-wyzwania'
                                                    ]
                                                }
                                                name={`q-7-wyzwania`}
                                                rowsMin={3}
                                                style={{ width: '100%' }}
                                                placeholder="Wyzwania..."
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formik.values[
                                                            'q-7-nie'
                                                        ].includes('On')}
                                                        value={'On'}
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        name={`q-7-nie`}
                                                    />
                                                }
                                                label="Nie realizowałem/-am takich badań"
                                            />
                                        </>
                                    )}
                                    {notEmpty2 &&
                                        type === 'customCheckbox' && ( // q6
                                            <>
                                                {!!formik.touched['q-6'] &&
                                                    formik.errors['q-6'] && (
                                                        <Alert
                                                            severity="error"
                                                            style={{
                                                                marginTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            {formik.touched[
                                                                'q-6'
                                                            ] &&
                                                                formik.errors[
                                                                    'q-6'
                                                                ]}
                                                        </Alert>
                                                    )}{' '}
                                                {q6Data.map(
                                                    (
                                                        { text, text2, custom },
                                                        q6Ind
                                                    ) => {
                                                        return (
                                                            <DynamicAnswer
                                                                key={`checkbox-${q6Ind}`}
                                                                text={text}
                                                                text2={text2}
                                                                custom={custom}
                                                                customName={
                                                                    'q-6'
                                                                }
                                                                index={
                                                                    q6Ind + 1
                                                                }
                                                                questionIndex={
                                                                    6
                                                                }
                                                                formik={formik}
                                                                // isError={errors.includes("q-5")}
                                                                // errors={errors}
                                                                textChange={
                                                                    handleOnChange
                                                                }
                                                            />
                                                        )
                                                    }
                                                )}
                                            </>
                                        )}
                                    {notEmpty2 && type === 'customRec' && (
                                        <>
                                            {!!formik.touched['q-8-rek-1'] &&
                                                formik.errors['q-8-rek-1'] && (
                                                    <Alert
                                                        severity="error"
                                                        style={{
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        {formik.touched[
                                                            'q-8-rek-1'
                                                        ] &&
                                                            formik.errors[
                                                                'q-8-rek-1'
                                                            ]}
                                                    </Alert>
                                                )}{' '}
                                            {recs.map((rec, recIndex) => {
                                                //TODO - zmienić recs na prawdziwe
                                                const recNumber = recIndex + 1
                                                return (
                                                    <Fragment key={recIndex}>
                                                        <Box
                                                            component="p"
                                                            fontWeight="500"
                                                        >{`Rekomendacja #${recNumber}`}</Box>
                                                        <Box
                                                            display="flex"
                                                            flexWrap="nowrap"
                                                        >
                                                            <TextareaAutosize
                                                                data-index={
                                                                    recIndex
                                                                }
                                                                name={`q-8-rek-${recNumber}`}
                                                                defaultValue={
                                                                    formik
                                                                        .values[
                                                                        `q-8-rek-${recNumber}`
                                                                    ]
                                                                }
                                                                onChange={
                                                                    handleOnChange
                                                                }
                                                                rowsMin={49}
                                                                style={{
                                                                    width: '70%',
                                                                    marginRight:
                                                                        '20px',
                                                                }}
                                                                placeholder=""
                                                            />
                                                            <CheckTable
                                                                handleChange={
                                                                    handleOnChange
                                                                }
                                                                formik={formik}
                                                                name={
                                                                    'q-8-rek_' +
                                                                    recNumber
                                                                }
                                                                width="auto"
                                                            />
                                                        </Box>
                                                    </Fragment>
                                                )
                                            })}
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    function compareNumbers(
                                                        a,
                                                        b
                                                    ) {
                                                        return a - b
                                                    }
                                                    let arr = []
                                                    recs.forEach((rec) => {
                                                        arr.push(
                                                            +rec.match(
                                                                /rek-(\d+)/
                                                            )[1]
                                                        )
                                                    })
                                                    const lastIndex =
                                                        arr.sort(
                                                            compareNumbers
                                                        )[arr.length - 1]
                                                    const newRec =
                                                        'q-8-rek-' +
                                                        (lastIndex + 1)
                                                    setRecs([...recs, newRec])
                                                }}
                                            >
                                                Dodaj rekomendację +
                                            </Button>
                                        </>
                                    )}
                                    {notEmpty2 && type === 'customArea2' && (
                                        <ProsCons
                                            formik={formik}
                                            anyRecs={recs.length}
                                            recs={recs}
                                            debouncedHandler={handleOnChange}
                                            error={false}
                                            questionIndex={questionIndex}
                                        />
                                    )}
                                </Box>
                            </Box>
                        )
                    }
                )}
                <div
                    style={{
                        maxWidth: '1100px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    {/* {!!showAlert && <Alert severity="error">{showAlert}</Alert>} */}
                </div>

                <p
                    style={{
                        marginTop: '50px',
                        textAlign: 'center',
                        display: 'flex',
                        position: touched ? 'sticky' : 'static',
                        zIndex: 10,
                        pointerEvents: 'none',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        maxWidth: '1100px',
                    }}
                >
                    {realQNumber === 1 ? (
                        <Button
                            style={{ pointerEvents: 'all' }}
                            variant="contained"
                            color="primary"
                            component={RouterLink}
                            to="/"
                        >
                            Strona Główna
                        </Button>
                    ) : (
                        <Button
                            style={{ pointerEvents: 'all' }}
                            variant="contained"
                            color="secondary"
                            onClick={prevQuestion}
                        >
                            <img
                                src={prevChev}
                                className={classes.chev}
                                style={{ marginRight: '10px' }}
                                alt=""
                            />{' '}
                            Wstecz
                        </Button>
                    )}
                    {realQNumber !== 9 && (
                        <Button
                            style={{ pointerEvents: 'all', marginLeft: '60px' }}
                            variant="contained"
                            color="secondary"
                            onClick={nextQuestion}
                        >
                            Dalej{' '}
                            <img
                                src={nextChev}
                                className={classes.chev}
                                style={{
                                    marginLeft: '10px',
                                }}
                            />
                        </Button>
                    )}
                    {realQNumber === 9 && (
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            style={{ marginLeft: '60px', pointerEvents: 'all' }}
                        >
                            Dalej{' '}
                            <img
                                src={nextChev}
                                className={classes.chev}
                                style={{
                                    marginLeft: '10px',
                                }}
                            />
                        </Button>
                    )}
                </p>
            </form>
        </>
    )
}
