import { mainTable } from "./mainTable";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormHelperText from "@material-ui/core/FormHelperText";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { useEffect } from "react";
const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      // width: 930,
    },
    cellSpanNested: {
      paddingLeft: "20px",
    },

    errorRadio: {
      "MuiIconButton-label > div": {
        color: "red",
      },
    },
    head: {
      background: "#f1f1f1",
      color: "white",
      textAlign: "center",
      "&>tr>th": {
        borderBottom: 0,
      },
    },
  })
);

function createMarkup(markup) {
  return {
    __html: markup,
  };
}

export default function CheckTable({
  type = "check",
  count = 5,
  name = "q-3",
  width = "930px",
  formik,
  handleChange,
}) {
  const classes = useStyles();
  let rowNumber = 0;
  let isError = false;
  let isTouched = false;
  if (type === "related") {
    isError = Object.keys(formik.errors).find((t) => t.startsWith("q-4"));
    isTouched = Object.keys(formik.touched).find((t) => t.startsWith("q-4"));
  }
  if (type === "check") {
    isError = Object.keys(formik.errors).find((t) => t.startsWith("q-3"));
    isTouched = Object.keys(formik.touched).find((t) => t.startsWith("q-3"));
  }
  const filteredQ3 = Object.keys(formik.values).filter((key) =>
    key.startsWith("q-3")
  );

  const showAlert = isError && isTouched && formik.errors[isError];

  // useEffect(() => {
  //   const checked = formik.values
  // }, [null])

  function uncheckRest({ target }) {
    const replaced = target.value.replace(/q-\d+/, "q-4");
    const replaced5 = target.value.replace(/q-\d+/, "q-5");
    if (!target.checked) {
      Object.keys(formik.values).forEach((key) => {
        if (key.startsWith(replaced)) {
          formik.setFieldValue(replaced + "_proba", "", false);
          formik.setFieldValue(replaced + "_pop", "", false);
        }
        if (key === replaced5) {
          if (Array.isArray(formik.values[key])) {
            formik.setFieldValue(key, [], false);
          }
        } else if (key.startsWith(replaced5 + "_open")) {
          formik.setFieldValue(key, "", false);
        }
      });
    }
  }

  return (
    <TableContainer
      className={classes.table}
      style={{ width }}
      component={Paper}
    >
      {!!showAlert && <Alert severity="error">{showAlert}</Alert>}
      <Table className={classes.table} style={{ width }} size="small">
        <TableBody>
          {mainTable.map(
            ({ text, isCustom, nested, standalone, related }, rowIndex) => {
              if (!standalone) {
                rowNumber++;
              }
              if (type === "related") {
                return (
                  <TableRow className={classes.row} key={rowIndex} hover>
                    {standalone &&
                      related.some((n) => formik.values["q-3"].includes(n)) && (
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: standalone ? 0 : "",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={createMarkup(text)}
                            className={nested ? classes.cellSpanNested : ""}
                          ></span>
                        </TableCell>
                      )}
                    {isCustom &&
                      formik.values["q-3"].includes(
                        "q-3_" + rowNumber + "_open"
                      ) && (
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: standalone ? 0 : "",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={createMarkup(text)}
                            className={nested ? classes.cellSpanNested : ""}
                          ></span>
                        </TableCell>
                      )}
                    {!standalone &&
                      formik.values["q-3"].includes("q-3_" + rowNumber) && (
                        <>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              borderBottom: standalone ? 0 : "",
                            }}
                          >
                            {isCustom ? (
                              <span
                                style={{
                                  fontStyle: "italic",
                                }}
                              >
                                {formik.values["q-3_" + rowNumber + "_open"]}
                              </span>
                            ) : (
                              <span
                                dangerouslySetInnerHTML={createMarkup(text)}
                                className={nested ? classes.cellSpanNested : ""}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.cell}
                            style={{
                              borderBottom: 0,
                            }}
                          >
                            <FormControl
                              component="fieldset"
                              style={{
                                margin: "0 20px",
                              }}
                            >
                              <TextField
                                error={
                                  isTouched &&
                                  isError &&
                                  Boolean(
                                    formik.errors[
                                      name + "_" + rowNumber + "_pop"
                                    ]
                                  )
                                }
                                color="secondary"
                                placeholder="Populacja"
                                size="small"
                                variant="outlined"
                                name={name + "_" + rowNumber + "_pop"}
                                defaultValue={
                                  formik.values[name + "_" + rowNumber + "_pop"]
                                }
                                onChange={handleChange}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.cell}
                            style={{
                              borderBottom: 0,
                            }}
                          >
                            <FormControl component="fieldset">
                              <TextField
                                error={
                                  isTouched &&
                                  isError &&
                                  Boolean(
                                    formik.errors[
                                      name + "_" + rowNumber + "_proba"
                                    ]
                                  )
                                }
                                placeholder="Próba"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                inputProps={{
                                  min: "0",
                                }}
                                type="number"
                                name={name + "_" + rowNumber + "_proba"}
                                defaultValue={
                                  formik.values[
                                    name + "_" + rowNumber + "_proba"
                                  ]
                                }
                                onChange={handleChange}
                              />
                            </FormControl>
                          </TableCell>
                        </>
                      )}
                  </TableRow>
                );
              }

              if (type === "check") {
                return (
                  <TableRow className={classes.row} key={rowIndex} hover>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: standalone ? 0 : "",
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={createMarkup(text)}
                        className={nested ? classes.cellSpanNested : ""}
                      ></span>
                      {isCustom && (
                        <TextField
                          name={name + "_" + rowNumber + "_open"}
                          defaultValue={
                            formik.values[name + "_" + rowNumber + "_open"]
                          }
                          label={"Inna metoda"}
                          style={{ display: "block" }}
                          onChange={handleChange}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.cell}
                      style={{
                        borderBottom: standalone ? 0 : "",
                      }}
                    >
                      {!standalone && (
                        <Checkbox
                          disabled={
                            isCustom &&
                            !formik.values?.[name + "_" + rowNumber + "_open"]
                          }
                          checked={formik.values[name]?.includes(
                            name + "_" + rowNumber
                          )}
                          name={name}
                          onChange={(e) => {
                            uncheckRest(e);
                            formik.handleChange(e);
                          }}
                          value={name + "_" + rowNumber}
                          color="secondary"
                          inputProps={{
                            name,
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
