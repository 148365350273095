import Box from "@material-ui/core/Box";
import React from "react";
import { ReactComponent as VirusSVG } from "./images/virus-outline.svg";
import MetricForm from "./MetricForm";

export default function Metric({ path }) {
  const wrapper = {
    maxWidth: "1000px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    padding: "0 20px",
  };
  return (
    <Box style={wrapper}>
      <Box
        style={{
          columnCount: 1,
          marginTop: "20px",
          color: "#4e5154",
          lineHeight: 1.4,
          fontSize: "18px",
        }}
      >
        <VirusSVG
          style={{
            opacity: 0.03,
            position: "absolute",
            width: "800px",
            height: "800px",
            maxWidth: "calc(100% - 40px)",
            pointerEvents: "none",
          }}
        />
        <p>Szanowni Państwo,</p>

        <p style={{ textAlign: "justify" }}>
          serdecznie dziękujemy za poświęcony czas i Państwa zaangażowanie oraz
          prosimy o udzielenie odpowiedzi na ostatnią grupę pytań. Zdajemy sobie
          sprawę, iż ich zakres może budzić Państwa wątpliwości dotyczące
          anonimowości ankiety. Bardzo jednak prosimy o udzielenie odpowiedzi na
          poniższe pytania, gdyż są one niezbędne m.in. do kontrolowania przez
          nas, w trakcie badania, struktury próby badawczej oraz do umożliwienia
          realizacji międzynarodowych badań porównawczych w oparciu o nasze
          wyniki. Pragniemy jednocześnie powtórzyć nasze zapewnienie, że
          pojedyncze ankiety nie będą analizowane ani prezentowane w
          jakiejkolwiek formie i posłużą wyłącznie do zaprezentowania szerokiego
          obrazu zjawiska.
        </p>

        <MetricForm />
      </Box>
    </Box>
  );
}
