import Box from "@material-ui/core/Box/Box";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import pattern from "./images/bg-pattern.png";
const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      background: `url(${pattern}), linear-gradient(to left,#f1f1f1,#f1f1f1)`,
    },
    stick: {
      position: "sticky",
      top: 0,
      zIndex: 9,
    },
  })
);

export default function Question({
  children,
  number,
  forceId = null,
  sticky = false,
}) {
  const classes = useStyles();
  const stickyClass = sticky ? " " + classes.stick : "";
  function createMarkup() {
    return { __html: children };
  }
  return (
    <Box
      p={4}
      className={classes.wrapper + stickyClass}
      mb={3}
      id={forceId ? `q-${forceId}` : `q-${number}`}
    >
      <Box style={{ maxWidth: "1100px", fontSize: "17px" }} mx="auto">
        <span style={{ fontWeight: 500 }}>Pytanie {number}.</span>{" "}
        <span dangerouslySetInnerHTML={createMarkup()} />
      </Box>
    </Box>
  );
}
