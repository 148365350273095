import { mainTable } from "./mainTable";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      width: 1100,
    },
    cellSpanNested: {
      paddingLeft: "20px",
    },
    errorRadio: {
      "MuiIconButton-label > div": {
        color: "red",
      },
    },
    head: {
      background: "#f1f1f1",
      color: "white",
      textAlign: "center",
      "&>tr>th": {
        borderBottom: 0,
      },
    },
    standalone: {
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  })
);

function createMarkup(markup) {
  return {
    __html: markup,
  };
}

function makeRadios(range, radioName, formik, standalone, isCustom) {
  let arr = [];
  for (let i = 0; i < range; i++) {
    arr.push(i);
  }
  return arr.map((elNumber, index) => {
    return (
      <TableCell
        key={index}
        align="center"
        style={{ borderBottom: standalone ? 0 : "" }}
      >
        {!standalone && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Radio
              disabled={
                isCustom ? !Boolean(formik.values[radioName + "_open"]) : false
              }
              checked={formik.values[radioName] === String(elNumber)}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={elNumber}
              name={radioName}
            />{" "}
            {elNumber ? elNumber : ""}
          </div>
        )}
      </TableCell>
    );
  });
}

export default function RadioTable({
  type = "radio",
  count = 6,
  name = "q-1",
  formik,
}) {
  const classes = useStyles();
  let rowNumber = 0;
  const debouncedChange = useDebouncedCallback((event) => {
    // event.persist();
    formik.handleChange(event);
  }, 1000);
  const handleOnChange = useCallback((event) => {
    event.persist();

    // const newValue = event.currentTarget.value;
    // setInnerValue(newValue);
    debouncedChange(event);
  }, []);
  return (
    <TableContainer
      className={classes.table}
      component={Paper}
      style={{ overflowX: "visible" }}
    >
      <Table stickyHeader className={classes.table} size="small">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              colSpan="5"
              align="center"
              style={{ letterSpacing: "6px" }}
            >
              Doświadczenie
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">
              Nie realizowałem/-am badań przy użyciu tej metody
            </TableCell>
            <TableCell align="center">Bardzo małe</TableCell>
            <TableCell align="center">Dość małe</TableCell>
            <TableCell align="center">Ani małe, ani duże</TableCell>
            <TableCell align="center">Dość duże</TableCell>
            <TableCell align="center">Bardzo duże</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mainTable.map(({ text, isCustom, nested, standalone }, rowIndex) => {
            const error = Boolean(formik.errors[name + "_" + rowNumber]);
            if (!standalone) {
              rowNumber++;
            }
            return (
              <TableRow
                className={
                  standalone
                    ? `${classes.row} ${classes.standalone}`
                    : classes.row
                }
                key={rowIndex}
                hover
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ borderBottom: standalone ? 0 : "" }}
                >
                  <span
                    dangerouslySetInnerHTML={createMarkup(text)}
                    className={nested ? classes.cellSpanNested : ""}
                  ></span>
                  {isCustom && (
                    <TextField
                      error={Boolean(
                        formik.errors[name + "_" + rowNumber + "_open"]
                      )}
                      name={name + "_" + rowNumber + "_open"}
                      defaultValue={
                        formik.values[name + "_" + rowNumber + "_open"]
                      }
                      label={"Inna metoda"}
                      style={{ display: "block" }}
                      onChange={handleOnChange}
                      onBlur={formik.handleBlur}
                    />
                  )}
                </TableCell>
                {makeRadios(
                  count,
                  name + "_" + rowNumber,
                  formik,
                  standalone,
                  isCustom,
                  error
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
