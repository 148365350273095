export const q6Data = [
  { text: "Własne doświadczenie badawcze " },
  { text: "Konsultacja wewnątrz zespołu projektowego" },
  {
    text: "Konsultacja ekspercka z przedstawicielami nauk społecznych z kraju, niebiorących udziału w projekcie",
  },
  {
    text: "Konsultacja ekspercka z przedstawicielami nauk społecznych z zagranicy, niebiorących udziału w projekcie",
  },
  { text: "Rekomendacje instytucji, w której pracuję" },
  {
    text: "Rekomendacje instytucji finansującej badanie",
    custom: true,
    text2: "<br /><em>[proszę podać nazwę tej instytucji]</em>",
  },
  {
    custom: true,
    text2: "<br /><em>[Jakie? proszę podać]</em>",
    text: "Inne ",
  },
];

export const dynamiAnswers = [
  {
    custom: true,
    text2: " <em>[proszę podać okres, w którym zbierane były dane]</em>",
    text: "Nie – zrealizowałem/-am (realizuję) tę część projektu bez żadnych modyfikacji<br /> ",
  },
  {
    custom: true,
    text2:
      "<em>[proszę podać pierwotnie zaplanowany termin rozpoczęcia prac oraz termin po wprowadzeniu zmian]</em>",
    text: "Tak – zmieniłem/-am kalendarz badań w zakresie zbierania danych <br />",
  },
  {
    custom: true,
    text2: "<em> [proszę, możliwie szczegółowo, opisać charakter zmian]</em>",
    text: "Tak – zmodyfikowałem/-am metodę pozyskania danych przy utrzymaniu dotychczasowych założeń projektu <br /> ",
  },
  {
    custom: true,
    text2: "<em>[proszę doprecyzować]</em>",
    text: "Tak – zmodyfikowałem/-am cele badawcze, by móc zrealizować tę część projektu przy użyciu innej metody <br />",
  },
  { text: "Zrezygnowałem/-am z realizacji tej części projektu" },
  { text: "Zrezygnowałem/-am z realizacji całego projektu" },
  { text: "Inne", custom: true, text2: "[Jakie? proszę podać]" },
];
