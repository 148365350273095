import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAZn1sUSDgC8YquiBDnSB13mv5Q0zUG5Jk",
  authDomain: "ankiety-da5d1.firebaseapp.com",
  projectId: "ankiety-da5d1",
  storageBucket: "ankiety-da5d1.appspot.com",
  messagingSenderId: "604618075882",
  appId: "1:604618075882:web:6a98ae0d65fbbbb8117193",
  measurementId: "G-YXTY5K5G5Y",
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const auth = firebase.auth();

export const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => auth.signInWithPopup(provider);
export default firebase;
