import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button/Button";
import Link from "@material-ui/core/Link/Link";
import { RouteComponentProps, Link as RouterLink } from "@reach/router";
import React from "react";
import { ReactComponent as VirusSVG } from "./images/virus-outline.svg";

export default function Home({}: RouteComponentProps) {
  const wrapper: any = {
    maxWidth: "1000px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    minHeight: "500px",
    fontSize: "20px",
  };
  return (
    <Box style={wrapper}>
      <VirusSVG
        style={{
          opacity: 0.03,
          position: "absolute",
          width: "800px",
          height: "800px",
          pointerEvents: "none",
        }}
      />
      <p>
        Serdecznie dziękujemy i przypominamy o możliwości kierowania pytań
        dotyczących naszych badań na następujące adresy e-mailowe:
      </p>
      <ul>
        <li>
          kierownika projektu - mgr Marcina Rechcińskiego (
          <Link href="mailto:marcin.rechcinski@uj.edu.pl">
            marcin.rechcinski@uj.edu.pl
          </Link>
          )
        </li>
        <li>
          opiekuna naukowego projektu - prof. dr hab. Małgorzaty
          Grodzińskiej-Jurczak (
          <Link href="mailto:m.grodzinska-jurczak@uj.edu.pl">
            m.grodzinska-jurczak@uj.edu.pl
          </Link>
          )
        </li>
      </ul>
    </Box>
  );
}
